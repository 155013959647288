import { useStaticQuery, graphql } from 'gatsby'
import { sort } from '@helpers/SingleWork'
import { WorkObject, WorkNode } from 'types'

const getFormattedProjects = (array: { node: WorkNode }[]): WorkObject[] => {
  const projects: WorkObject[] = []

  array.map(({ node }) => {
    const { id, entryTitle, color, slug, excerptFIrst, position } = node
    const project = {
      id,
      color,
      slug,
      position,
      title: entryTitle,
      text: excerptFIrst?.excerptFIrst,
    }

    return projects.push(project)
  })

  return sort(projects)
}

export const useFeaturedProjects = (): WorkObject[] => {
  const graphData = useStaticQuery(graphql`
    query featuredProjects {
      allContentfulPortfolioItem {
        edges {
          node {
            id
            position
            entryTitle
            color
            slug
            excerptFIrst {
              excerptFIrst
            }
          }
        }
      }
    }
  `)

  const { edges } = graphData.allContentfulPortfolioItem

  return getFormattedProjects(edges)
}
