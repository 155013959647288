import React from 'react'
import { OutboundLink } from 'gatsby-plugin-google-gtag'
import Arrow from '@partials/Arrow'
import Slider from '@slider'
import Image from '@partials/Image'
import { SingleWorkObject } from 'types'

type Props = {
  data: SingleWorkObject
}

const WorkTemplateContent: React.FC<Props> = ({ data }) => {
  const { tags, role, resume, url, mainImage, images } = data

  return (
    <div className="columns is-multiline work-content">
      <div className="column is-6 work-content__tags p-mb-0">
        <h3 className="wow fadeIn" data-wow-duration="1.5s">
          Tech used:
        </h3>
        <div className="tags-wrapper">
          {tags?.map((tag, index) => (
            <p className="w-mb-50 wow fadeIn" data-wow-duration={`${0.05 * index}s`} key={tag}>
              {tag}
            </p>
          ))}
        </div>
        <h3 className="wow fadeIn" data-wow-duration="1.5s">
          Role:
        </h3>
        <span className="work__role  work__role wow fadeIn" data-wow-duration="1.5s">
          {role}
        </span>
        <OutboundLink
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          className="btn is-light has-icon wow fadeIn"
          title="Visit site"
        >
          Visit site
          <div className="arrow-container">
            <Arrow />
            <Arrow />
          </div>
        </OutboundLink>
      </div>
      <div className="column is-6 work-content__resume p-mb-0">
        <p className="wow fadeIn" data-wow-duration="1.5s">
          {resume}
        </p>
        <div className="column is-12">
          <Image animate fadeType="fadeIn" wowDuration={1.5} src={mainImage.url} alt={mainImage.alt} />
        </div>
      </div>
      <div className="column is-12 work-content__slider">
        {images?.length !== 0 ? (
          <Slider
            className="work-slider"
            settings={{
              preloadImages: false,
              lazy: true,
              slidesPerView: 1,
              setWrapperSize: true,
              spaceBetween: 50,
              navigation: true,
              breakpoints: {
                768: {
                  slidesPerView: 2,
                },
              },
            }}
          >
            {images?.map(({ url: workUrl, alt }, i) => (
              <div
                key={workUrl}
                className="work-slide wow fadeIn"
                data-wow-duration="1.5s"
                data-wow-delay={`${0.25 * i}s`}
              >
                <Image src={workUrl} alt={alt} />
              </div>
            ))}
          </Slider>
        ) : null}
      </div>
    </div>
  )
}

export default WorkTemplateContent
