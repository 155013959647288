import React from 'react'
import Link from '@partials/Link'
import Image from '@partials/Image'

import github from '@images/github.svg'
import linkedin from '@images/linkedin.svg'
import mail from '@images/mail.svg'
import '@sass/components/Footer.scss'

const Footer: React.FC = () => (
  <div className="column is-12 app-footer">
    <p>Eiker Yejan &copy; 2021, Front-End Developer</p>
    <ul>
      <li>
        <Link title="About" href="/about">
          About
        </Link>
      </li>

      <li>
        <Link title="Works" href="/works">
          Works
        </Link>
      </li>

      <li>
        <Link title="Contact" href="/contact">
          Contact
        </Link>
      </li>
    </ul>
    <ul className="social-links">
      <li>
        <a target="_blank" rel="noopener noreferrer" href="https://github.com/EikerYejan" title="Github">
          <Image src={github} alt="github-icon" />
        </a>
      </li>
      <li>
        <a target="_blank" rel="noopener noreferrer" href="https://linkedin.com/in/eikeryejan" title="Linkedin">
          <Image src={linkedin} alt="linkedin-icon" />
        </a>
      </li>
      <li>
        <a target="_blank" rel="noopener noreferrer" href="mailto:eikeryejan@gmail.com" title="Mail">
          <Image src={mail} alt="mail-icon" />
        </a>
      </li>
    </ul>
  </div>
)

export default Footer
