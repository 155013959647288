import React from 'react'
import { useFeaturedProjects } from '@hooks/useFeaturedProjects'
import Slider from '@slider'
import Button from '@button'
import '@sass/components/about/FeaturedProjects.scss'

type Props = {
  currentProjectId?: string
}

const FeaturedProjects: React.FC<Props> = ({ currentProjectId }) => {
  const items = useFeaturedProjects()

  return (
    <div className="featured-projects">
      {items?.length !== 0 ? (
        <>
          <h2 className="section-title wow fadeIn has-after has-after--bottom">Featured projects</h2>
          <Slider
            className="featured-projects__slider"
            settings={{
              slidesPerView: 1,
              spaceBetween: 50,
              navigation: true,
              breakpoints: {
                768: {
                  slidesPerView: 3,
                },
              },
            }}
          >
            {items
              .filter((item) => (currentProjectId ? item.id !== currentProjectId : true))
              .map(({ title, text, slug, color, id }, i) => (
                <div
                  key={id}
                  className="featured-project-slide wow fadeIn"
                  data-wow-delay={`${0.25 * i}s`}
                  style={{ background: color }}
                >
                  <div className="featured-project-slide__content">
                    <h3>{title}</h3>
                    <p>{text}</p>
                    <Button title={title} href={`/${slug}`} className="has-arrow">
                      Read more
                    </Button>
                  </div>
                </div>
              ))}
          </Slider>
        </>
      ) : null}
    </div>
  )
}

export default FeaturedProjects
