import React from 'react'
import { usePageHeader } from '@hooks/usePageHeader'
import { PageHeader as PageHeaderProps } from 'types'
import '@sass/components/PageHeader.scss'

type Props = PageHeaderProps & { color: string }

const PageHeader: React.FC<Props> = ({ title, excerpt, color }) => {
  const gradient = usePageHeader(color)

  return (
    <div
      className="columns is-multiline page-header"
      style={{
        backgroundImage: gradient,
      }}
    >
      <div className="column is-4 page-header__left">
        <h1 className="animated fadeIn delay-0-5s">{title?.first ?? title}</h1>
        <h2 className="animated fadeIn delay-0-5s">{title?.second}</h2>
      </div>
      <div className="column is-8 page-header__right">
        <div className="page-header__right-content w-mb-100">
          <p className="animated fadeIn delay-0-8s has-after has-after--bottom">{excerpt?.first ?? excerpt}</p>
          <span className="animated fadeIn delay-0-8s">{excerpt?.second ?? null}</span>
        </div>
      </div>
    </div>
  )
}

export default PageHeader
