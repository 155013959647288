import { useState, useEffect } from 'react'

/**
 * Update page-header background gradient on resize
 * @param {String} color
 */
export const usePageHeader = (color: string): string | undefined => {
  const [gradient, setGradient] = useState<string>()

  useEffect(() => {
    const getGradient = (): void => {
      const vw = window.innerWidth
      const [direction, stop] = vw < 768 ? ['0deg', '25%'] : ['90deg', '20%']
      const newGradient = `linear-gradient(${direction}, #0b0c0c ${stop}, ${color} 0)`

      setGradient(newGradient)
    }

    getGradient()
    window.addEventListener('resize', getGradient)
  }, [color])

  return gradient
}
